export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Escape Road Game",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://azgames.io/escape-road.embed",
    domain: "escaperoadgame.net",
    gaId: "G-BMMNB7CPQF",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
